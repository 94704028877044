import React from 'react';

import './styles.css';

class HorizontalScrollContainer extends React.Component {
  static defaultProps = {
    sensibility: 100,
    onScroll: () => {},
    invert: false,
  };

  componentDidMount() {
    document.querySelector('.container').addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    document.querySelector('.container').removeEventListener('wheel', this.handleWheel);
  }

  handleWheel = event => {
    if (event.deltaY < 0) {
      if (this.props.invert) {
        document.querySelector('.container').scrollLeft -= this.props.sensibility;
      } else {
        document.querySelector('.container').scrollLeft += this.props.sensibility;
      }
    }

    if (event.deltaY > 0) {
      if (this.props.invert) {
        document.querySelector('.container').scrollLeft += this.props.sensibility;
      } else {
        document.querySelector('.container').scrollLeft -= this.props.sensibility;
      }
    }
  };

  render() {
    return (
      <div className="container" onScroll={() => this.props.onScroll()}>
        {this.props.children}
      </div>
    );
  }
}

export default HorizontalScrollContainer;
